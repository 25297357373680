import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
//import { Styles } from "./styles/breadcrumb.js";
import '../../components/common/css/breadcrumb.css'
import { Link } from 'react-router-dom';

export class BreadcrumbBox extends Component {
    state = {
        backgroundImage: '1920X1003.jpg',
    }

    render() {
        return (
            
                <section className="breadcrumb-area" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/bg/${this.state.backgroundImage})` }}>
                    <Container>
                        <Row>
                            <Col md="12" className="text-center">
                                <div className="breadcrumb-box">
                                    <h2 className="breadcrumb-title">
                                        {this.props.title}
                                    </h2>
                                    <Breadcrumb>
                                        <Breadcrumb.Item><Link to={'/'}>Home</Link></Breadcrumb.Item>
                                        <Breadcrumb.Item active>{this.props.title}</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            // </Styles>
        )
    }
}