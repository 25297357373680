import React, { Component } from 'react';
import { Container, Row, Col, Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { Styles } from "./styles/tabBox.js";
import styled from "styled-components";
import { colors } from "../components/common/element/elements.js";
import '../components/common/css/tab.css'



const TabBox = () => {
        return (
            
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey="why">
                            <Row>
                                <Col lg="3" md="4" className='nav-item'>
                                    <Nav className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="why"><i className="las la-arrow-right"></i> Why Choose Us</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="mission"><i className="las la-arrow-right"></i> Our Mission</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vision"><i className="las la-arrow-right"></i> Our Vision</Nav.Link>
                                        </Nav.Item>
                                       
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="why">
                                            <h4 className="tab-title">Why Choose Us </h4>
                                            <ul className="list-unstyled check-list">
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    <b>Civic Sense: </b>
                                                    "Fostering civic sense cultivates a strong sense of community and responsibility, preparing students to actively contribute to societal well-being and become informed global citizens.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    <b>STEAM Integration: </b>
                                                    By seamlessly integrating Science, Technology, Engineering, Arts, and Mathematics (STEAM) into our curriculum, we empower students with interdisciplinary skills, encouraging innovation and critical thinking.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    <b>Practical Life Skills: </b>
                                                    We prioritize practical life skills, equipping students with the essential tools needed for real-world challenges, fostering resilience, adaptability, and a proactive approach to problem-solving.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    <b>Cultural Values and Arabic Learning: </b>
                                                    Embracing Cultural values and Arabic learning fosters cultural enrichment, instilling strong moral principles for students to build a foundation of ethics in their lives.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    <b>Facilities</b>
                                                    At Paramount International School, we take pride in our modern facilities tailored to enrich every aspect of a student's educational journey. Our computer labs foster digital literacy, while expansive and well-equipped classrooms provide a comfortable space for optimal learning. The vibrant play area encourages physical development, and our dedicated gym room promotes a healthy lifestyle. Our library stands as a hub for knowledge and exploration. Together, these facilities create an environment that supports the holistic growth and well-being of our students.
                                                </li>
                                                <li>
                                                    <i className="fa fa-check"></i>
                                                    <b>Academic Information</b>
                                                    At Paramount International School we take pride in offering a world-class education aligned with the prestigious Oxford Curriculum. Rooted in academic rigor and innovation, our curriculum draws from the rich tradition of Oxford's educational excellence. This approach not only ensures a globally recognized standard but also nurtures in our students a deep appreciation for critical inquiry, analytical thinking, and a well-rounded education that extends beyond the classroom. Through the Oxford Curriculum, we are committed to providing an enriching and intellectually stimulating academic experience that prepares our students for the challenges and opportunities of the future.
                                                </li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mission">
                                            <h4 className="tab-title">Our Mission</h4>
                                            <p className="tab-desc">
                                            At Paramount International School, we are committed to a holistic education that integrates moral and Islamic values, fostering compassionate and responsible individuals for a positive impact in their communities.
                                            </p>
                             
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="vision">
                                            <h4 className="tab-title">Our Vision</h4>
                                            <p className="tab-desc">
                                            To emerge as a world leader in nurturing forward-thinking and empathetic individuals, prepared to thrive in an ever-changing global environment.    
                                            </p>
                                           
                                        </Tab.Pane>
                             
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            
        )
    
}

export default TabBox
