import React from 'react';
import { Styles } from '../styles/courseSearch.js';
import '../../../components/common/css/courseSearch.css'

const CourseSearch = () => {

  
        return (
            
                <div className="course-search">
                    <h5>Search Course</h5>
                    <form action="#">
                        <input type="text" name="search" placeholder="Search Here" />
                        <button type="submit"><i className="las la-search"></i></button>
                    </form>
                </div>
            
        )
}

export default CourseSearch
