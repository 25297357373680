import React, { Component } from 'react';
import Datas from '../data/hero/hero-slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/heroSlider.js";

class HeroSlider extends Component {
    render() {
        const settings = {
            slidesPerView: 1,
            loop: true,
            speed: 3000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.slider-button-next',
                prevEl: '.slider-button-prev'
            },
            renderPrevButton: () => (
                <div className="swiper-btn slider-button-prev" style={{display:'none'}}><i className="flaticon-arrow-left-th"></i></div>
            ),
            renderNextButton: () => (
                <div className="swiper-btn slider-button-next" style={{display:'none'}}><i className="flaticon-arrow-right-th"></i></div>
            )
        };

        return (
            <Styles>
                {/* Hero Slider */}
                <section className="hero-slider-area">
                    <Swiper {...settings}>
                        {
                            Datas.map((data, i) => (
                                <div className="slider-item" key={i}>
                                    <div className="image-container">
                                        {/* <img src={process.env.PUBLIC_URL + `/assets/images/slider/${data.backgroundImage}`} className="slider-image" style={{width:'100%'}} alt={data.backgroundImage} /> */}
                                        <video autoPlay muted loop src={process.env.PUBLIC_URL + `/assets/images/slider/IMG_6395.mp4`} className="slider-image" style={{width:'100%'}} ></video>
                                    </div>
                                    <div className="slider-table">
                                        <div className="slider-tablecell">
                                            <Container>
                                                <Row>
                                                    <Col md="12">
                                                        <div className={data.uniqClass}>
                                                            <div className="slider-title">
                                                                <p>
                                                                    <span style={{padding:5,backgroundColor:'yellow',fontSize:14,color:'#000'}}>
                                                                        Welcome To 
                                                                    </span>
                                                                     <hr/>
                                                                    <span style={{backgroundColor:'#182B49',padding:10}}>
                                                                        Paramount International School
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="slider-desc d-none d-md-block">
                                                                <h1><span style={{opacity: 0.9}}>{data.desc}</span></h1>
                                                            </div>
                                                        {/*
                                                            <div className="slider-btn">
                                                                <Link className="slider-btn1" to={process.env.PUBLIC_URL + `/${data.btnOneLink}`}>Our Courses</Link>
                                                                <Link className="slider-btn2" to={process.env.PUBLIC_URL + `/${data.btnTwoLink}`}>Contact Us</Link>
                                                            </div>
                                                            */}
                                                        </div> 
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Swiper>
                </section>
            </Styles>
        )
    }
}

export default HeroSlider
