import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Styles } from './styles/contact.js';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMap extends Component {

    static defaultProps = {
        center: {
            lat: 40.696295,
            lng: -73.997619
        },
        zoom: 11
    };

    render() {
        return (
            <Styles>
                {/* Google Map */}
                <div className="google-map-area">
                    {/* <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyATY4Rxc8jNvDpsK8ZetC7JyN4PFVYGCGM" }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                    >
                        <AnyReactComponent lat={40.696295} lng={-73.997619} text="My Marker" />
                    </GoogleMapReact> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13618.262442682948!2d74.2421325!3d31.4260922!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391901eceecee639%3A0x17671ab689a954bd!2sParamount%20International%20School!5e0!3m2!1sen!2s!4v1708115180141!5m2!1sen!2s"  style={{border:0,height:'100%',width:'100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </Styles>
        )
    }
}

export default GoogleMap