import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";




import HomeTwo from './HomeTwo';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails from './pages/blog/BlogDetails';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';
import OurFounder from './pages/instructor/founder';
import FounderDetails from './pages/instructor/founderDetails'
import FeePolicy from './pages/policy/FeesPolicy'
import GradePolicy from './pages/policy/Grade'
import RulesRegulations from './pages/policy/Rules'
import AcademicStructure from './pages/academic/Structure'
import HashLoader from "react-spinners/HashLoader";
import HomeOne from './HomeOne';

// const HomeOne = React.lazy(() => import('./HomeOne'))
function App() {

    const myElementRef = useRef(null);
    const [loading,setLoading] = useState(false)
    const [data, setData] = useState(null);
    useEffect(() =>{
        setLoading(true)
        setTimeout(()=>{
            setLoading(false)
        },5000)
        if (myElementRef.current) {
            myElementRef.current.classList.add('my-class');
        }
        // fetchData();
    },[])

    // const fetchData = async () => {
	// 	try {
	// 	  // Simulating an API call
	// 	  const response = await new Promise((resolve) =>
	// 		setTimeout(() => resolve({ data: 'Some data' }), 2000)
	// 	  );
	// 	  setData(response.data);
	// 	  setLoading(false); // Data is fetched, stop loading
	// 	} catch (error) {
	// 	  console.error('Error fetching data:', error);
	// 	  setLoading(false); // Stop loading even if there's an error
	// 	}
    // };

    if(loading){
        return(
            <div style={{background:'#125b8b',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100vh'}}>
                <HashLoader
                    color={'#f1933d'}
                    loading={loading}
                    //cssOverride={override}
                    
                    size={60}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        )
    }else{

        return (
            <>
            {loading?
                <div style={{background:'#125b8b',textAlign:'center',display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100vh'}}>
                    <HashLoader
                        color={'#f1933d'}
                        loading={loading}
                        //cssOverride={override}
                        
                        size={60}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            :
                <Router>
                    <GlobalStyle />
                    <ScrollToTop />
                    <Routes>
                        <Route exact path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} element={<HomeTwo/>} />
                        {/* <Route path={`${process.env.PUBLIC_URL + "/founder"}`} element={<OurFounder/>} /> */}
                        <Route path={`${process.env.PUBLIC_URL + "/founder"}`} element={<FounderDetails/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/our-academic-structure"}`} element={<AcademicStructure/>} />
                        
                        <Route path={`${process.env.PUBLIC_URL + "/fee-policies"}`} element={<FeePolicy/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/assessment-policy"}`} element={<GradePolicy/>}/>
                        <Route path={`${process.env.PUBLIC_URL + "/rules-regulations"}`} element={<RulesRegulations/>}/>
                        <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} element={<CourseGrid/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/course-list"}`} element={<CourseList/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/course-details"}`} element={<CourseDetails/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/instructor"}`} element={<Instructor/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/instructor-details/:id"}`} element={<InstructorDetails/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} element={<Gallery/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/events"}`} element={<Events/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/event-details"}`} element={<EventDetails/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/login"}`} element={<Login/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/registration"}`} element={<Register/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/faq"}`} element={<Faq/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/404"}`} element={<PageNotFound/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/coming-soon"}`} element={<ComingSoon/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} element={<BlogClassic/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} element={<BlogGrid/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`} element={<BlogDetails/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/products"}`} element={<Product/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/product-details"}`} element={<ProductDetails/>} />
                        <Route path={`${process.env.PUBLIC_URL + "/cart"}`} element={<Cart/>} />
                    </Routes>
                </Router>
            }
            </>
        )
    }
}

export default App;