import React from 'react';
import Datas from '../../data/rules/rules.json';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import '../../components/common/css/faq.css'
const RulesRegulations = () => {
        return (
            
            <div className="main-wrapper faq-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Rules and Regulations" />

                    {/* Faq Area */}
                    <section className="faq-area">
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Tab.Container defaultActiveKey="general">
                                        {/* <Nav className="justify-content-center">
                                            <Nav.Item><Nav.Link eventKey="general">Age group Policy</Nav.Link></Nav.Item>
                                        </Nav> */}
                                        <Tab.Content>
                                            <Tab.Pane eventKey="general">
                                                <Row>
                                                    {
                                                        Datas.map((data, i) => (
                                                            <Col md="12" key={i}>
                                                                <div className="faq-item">
                                                                    <div className="faq-title d-flex">
                                                                        <div className="title-icon"><span>{data.id}</span></div>
                                                                        <div className="title-text"><p>{data.title}</p></div>
                                                                    </div>
                                                                    {/* <div className="faq-desc">
                                                                        <p>{data.faqDesc}</p>
                                                                    </div> */}
                                                                </div>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

            </div>
            
        )
    
}

export default RulesRegulations