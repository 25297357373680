import React, { useEffect,useRef  } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import BackToTop from './common/BackToTop';
//import { Styles } from "./styles/footerTwo.js";
import '../components/common/css/footerTwo.css'
import Datas from '../data/footer/footer2.json';

function FooterTwo() {
    const myElementRef = useRef(null);

    useEffect(() => {
        const form = document.getElementById("form4");
        const email = document.getElementById("email4");

        form.addEventListener("submit", formSubmit);

        function formSubmit(e) {
            e.preventDefault();

            const emailValue = email.value.trim();

            if (emailValue === "") {
                setError(email, "Email can't be blank");
            } else if (!isEmail(emailValue)) {
                setError(email, "Not a valid email");
            } else {
                setSuccess(email);
            }
        }

        function setError(input, message) {
            const formControl = input.parentElement;
            const errorMsg = formControl.querySelector(".input-msg4");
            formControl.className = "form-control error";
            errorMsg.innerText = message;
        }

        function setSuccess(input) {
            const formControl = input.parentElement;
            formControl.className = "form-control success";
        }

        function isEmail(email) {
            return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
        }

        if (myElementRef.current) {
            myElementRef.current.classList.add('my-class');
        }
    });
    //* Footer Two 
    return (
            <footer className="footer2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.backgroundImage})` }}>
                <>
                    <Container>
                        <Row>
                            <Col md="4">
        
                                <div className="footer-logo-info">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/paramount-logo-white.png"} alt="" className="img-fluid" />
                                    <ul className="list-unstyled">
                                        <li><i className="las la-map-marker"></i>Punjab University Employees Housing Society (Town 2) Raiwind Road ,Lahore</li>
                                        <li><i className="las la-envelope"></i>paramountintlschool@gmail.com </li>
                                        <li><i className="las la-phone"></i>+92 317 4632660</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-links">
                                    <h5>Useful Links</h5>
                                    <ul className="list-unstyled">
                                        <li> <Link to={process.env.PUBLIC_URL+"/contact"} > <i className="las la-angle-right"></i>Contact Us</Link></li>
                                        <li> <Link to={process.env.PUBLIC_URL+"/fee-policies"}> <i className="las la-angle-right"></i>Tuition and Fees Policy</Link></li>
                                        <li> <Link to={process.env.PUBLIC_URL+"/assessment-policy"}> <i className="las la-angle-right"></i>Age Group and Assessment Policy</Link></li>
                                        <li> <Link to={process.env.PUBLIC_URL+"/rules-regulations"}> <i className="las la-angle-right"></i>Rules and Regulations</Link></li>
                                        {/* <li><Link to={process.env.PUBLIC_URL + "/"}><i className="las la-angle-right"></i>Online Support</Link></li> */}
                                    </ul>
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="f-newsletter">
                                    <h5>Newsletter</h5>
                                    {/* <p>Lorem ipsum dolor sit amet, consectet adipisicing elit.</p> */}

                                    <form id="form4" className="form">
                                        <p className="form-control">
                                            <input type="email" placeholder="Enter email here" id="email4" />
                                            <span className="input-msg4"></span>
                                        </p>
                                        <button>Submit</button>
                                    </form>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="copytext-area text-center">
                                    <p>Copyright &copy; 2024 | Powered by  
                                        <a href={'https://hinttch.io/'} target="_blank" rel="noopener noreferrer">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/hinttechiconwhite.png"} alt="" />
                                            <b style={{color:'white'}}>HINT TECH SOLUTIONS</b>    
                                        </a>
                                    </p>
                                    {/* <ul className="social list-unstyled list-inline">
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-twitter"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-youtube"></i></a></li>
                                        <li className="list-inline-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fab fa-dribbble"></i></a></li>
                                    </ul> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                
                    {/* Back To Top  */}
                    <BackToTop />
                </>
            </footer>
    );
}

export default FooterTwo
