import React from 'react';
import Datas from '../../data/faq/faq.json';
import { Container, Row, Col, Tab, Nav, Table } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import '../../components/common/css/faq.css'
const GradePolicy = () => {
        return (
            
            <div className="main-wrapper faq-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Age Group and Assessment Policy" />

                    {/* Faq Area */}
                    <section className="faq-area">
                        <Container>
                            <Row>
                                <Col md="12">
                                    <Tab.Container defaultActiveKey="general">
                                        {/* <Nav className="justify-content-center">
                                            <Nav.Item><Nav.Link eventKey="general">Age group Policy</Nav.Link></Nav.Item>
                                        </Nav> */}
                                        <Tab.Content>
                                            <Tab.Pane eventKey="general">
                                                <Row>
                                                    <h2 style={{textAlign:'center'}}>AGE GROUP POLICY</h2>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>PRE-SCHOOL</th>
                                                                <th>AGE</th>
                                                                <th>PRIMARY SCHOOL</th>
                                                                <th>AGE</th>
                                                                <th>HIGH SCHOOL</th>
                                                                <th>AGE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Play Group</td>
                                                                <td>3+</td>
                                                                <td>Grade I</td>
                                                                <td>6+</td>
                                                                <td>Grade V</td>
                                                                <td>10+</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Nursery</td>
                                                                <td>4+</td>
                                                                <td>Grade II</td>
                                                                <td>7+</td>
                                                                <td>Grade VI</td>
                                                                <td>11+</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Prep</td>
                                                                <td>5+</td>
                                                                <td>Grade III</td>
                                                                <td>8+</td>
                                                                <td>Grade VII</td>
                                                                <td>12+</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td>Grade IV</td>
                                                                <td>9+</td>
                                                                <td>Pre-IX</td>
                                                                <td>13+</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>Grade X</td>
                                                                <td>14+</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <h2>ASSESSMENT POLICY</h2>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>PRE-SCHOOL</th>
                                                                <th>PRIMARY SCHOOL</th>
                                                                <th>HIGH SCHOOL</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>First Term</td>
                                                                <td>First Term</td>
                                                                <td>First Term</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Second Term</td>
                                                                <td>Second Term</td>
                                                                <td>Second Term</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Final Term</td>
                                                                <td>Final Term</td>
                                                                <td>Final Term</td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

            </div>
            
        )
    
}

export default GradePolicy