import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";

function MobileMenu() {
    const myElementRef = useRef(null);
    const [sidebar,setSidebar] = useState('')

    useEffect(() => {
        // Mobile Menu
        if (myElementRef.current) {
            myElementRef.current.classList.add('new-class');
        } else {
            console.error('Element not found');
        }

        const hmBtn = document.getElementById("mb-sidebar-btn");

        if (hmBtn) {
            const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
            // const mdSidebarBody = document.getElementById("mb-sidebar-body");
            const mdSidebarExit = document.getElementById("close-mb-sidebar");

            hmBtn.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.toggle("visible");
                // mdSidebarBody.classList.toggle("opened");
                
            });

            mdSidebarOverlay.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                // mdSidebarBody.classList.remove("opened");
            });

            mdSidebarExit.addEventListener("click", function (e) {
                e.preventDefault();
                mdSidebarOverlay.classList.remove("visible");
                // mdSidebarBody.classList.remove("opened");
            });
        }

        // Menu Accordion -----------------
        const menuButton = document.querySelectorAll(".mb-menu-button");
        menuButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "mb-menu-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "mb-menu-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });
    const handleSidebar = () =>{
        setSidebar('opened')
    }
    const handleSidebarClose = () =>{
        setSidebar('')
    }
    
    return (
        <Styles ref={myElementRef}>
            {/* Mobile Menu */}
            <section className="mobile-menu-area">
                <Container>
                    <Row>
                        <Col md="0" sm="12">
                            <div className="mb-topbar d-flex justify-content-between">
                                
                                <div className="topbar-item">
                                    <p><a href='tel:923174632660' style={{textDecoration:'none',color:'rgb(17, 182, 122)'}}><i className="las la-phone"></i>+92 317 4632660</a></p>
                                </div>
                                {/* <div className="topbar-item">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/login"}>Log In</Link></li>
                                        <li className="list-inline-item">/</li>
                                        <li className="list-inline-item"><Link to={process.env.PUBLIC_URL + "/registration"}>Register</Link></li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="mb-logo-area d-flex justify-content-between">
                                <div className="mb-logo-box d-flex">
                                    <div className="hm-button">
                                        <a onClick={handleSidebar} style={{color:'rgb(17, 182, 122)',cursor:'cell'}} >
                                            <i style={{fontSize:30}} className="las la-bars"></i>
                                        </a>
                                    </div>
                                    <div className="mb-logo">
                                        
                                        <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/paramount-logo-1.png"} alt="" /></Link>
                                        
                                    </div>
                                </div>
                                {/* <div className="mb-search-box">
                                    <form action="#">
                                        <input type="text" name="search" placeholder="Search Here" />
                                        <button type="submit"><i className="las la-search"></i></button>
                                    </form>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mobile Menu Sidebar */}
            <section className={`mb-sidebar ${sidebar}`} id="mb-sidebar-body">
                
                <div className="mb-sidebar-heading d-flex justify-content-between">
                    <div><h5>Menu</h5></div>
                    <div><a  onClick={handleSidebarClose} style={{color:'#fff',cursor:'cell'}}><i className="las la-times"></i></a></div>
                </div>

                <div className="mb-sidebar-menu" style={{display:'block !important'}}>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active" to={process.env.PUBLIC_URL + "/"}>
                            <p><Link to={process.env.PUBLIC_URL + "/"} style={{color:'#fff',textDecoration:'none',fontWeight:600}}> Home </Link></p>
                        </button>
                        <div className="mb-menu-content show">
                            {/* <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p style={{color:'#fff',textDecoration:'none',fontWeight:600}}>Founder & Team <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content ">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/founder"} style={{color:'#fff',textDecoration:'none'}}>Founder</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/instructor"} style={{color:'#fff',textDecoration:'none'}}>Team</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p><Link to={process.env.PUBLIC_URL + "/our-academic-structure"} style={{color:'#fff',textDecoration:'none',fontWeight:600}}>Academic Structure</Link></p>
                        </button>
                        <div className="mb-menu-content show">
                            {/* <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                            </ul> */}
                        </div>
                    </div>

                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p><Link to={process.env.PUBLIC_URL + "/gallery"} style={{color:'#fff',textDecoration:'none',fontWeight:600}}>Gallery</Link></p>
                        </button>
                        <div className="mb-menu-content show">
                            {/* <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p><Link to={process.env.PUBLIC_URL + "/blog-grid"} style={{color:'#fff',textDecoration:'none',fontWeight:600}}>Blog's</Link></p>
                        </button>
                        <div className="mb-menu-content show">
                            {/* <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p><Link to={process.env.PUBLIC_URL + "/about"} style={{color:'#fff',textDecoration:'none',fontWeight:600}}>About Us</Link></p>
                        </button>
                        <div className="mb-menu-content show">
                            {/* <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                            </ul> */}
                        </div>
                    </div>

                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Instructor <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Event <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/event-details"}>Event Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Blog <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Shop <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </section>
            <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
        </Styles>
    )
}

export default MobileMenu