import React, { Component, useEffect, useState } from 'react';
import Datas from '../../data/blog/grid.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from './../../components/Pagination';
import BlogSidebar from './components/BlogSidebar';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/blog.js';

import '../../components/common/css/blog.css'
import axios from 'axios';

const BlogGrid = () => {
    // const adminBaseURL = 'http://localhost/KissanTimeProject/admin_dashboard/'
    // const ApibaseURL = 'http://localhost/api/news/'
    // const [blogData ,setBlogData] = useState([])
    // const [limit,setLimit] = useState(20)
    // const getBlogsData = () =>{
    //     axios.get(`${ApibaseURL}blogs.php?token=123456789&_blogs_limit=${limit}`)
    //     .then(res=>{
    //         const alldata = res?.data?.data
    //         setBlogData(alldata)
    //     })
    // }

    // useEffect(() =>{
    //     getBlogsData()
    // },[])
   
        return (
           
                <div className="main-wrapper blog-grid-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Blog's" />

                    {/* Blog Classic */}
                    <section className="blog-grid-area">
                        <Container>
                            <Row>
                                <Col lg="12" md="12" sm="12">
                                    <Row>
                                        {
                                            // blogData.map((data, i) => (
                                            //     <Col lg="4" md="12" key={i}>
                                            //         <div className="blog-item">
                                            //             <div className="blog-img">
                                            //                 <Link to={process.env.PUBLIC_URL + data.blog_id}><img src={adminBaseURL+data.img_path+data.blog_img} alt="" className="img-fluid" /></Link>
                                            //             </div>
                                            //             <div className="blog-content">
                                            //                 <div className="blog-auth_date d-flex">
                                            //                     <div className="author-img d-flex">
                                            //                         {/* <Link to={process.env.PUBLIC_URL + data.authorLink}><img src={process.env.PUBLIC_URL + `/assets/images/${data.authorImg}`} alt="" /></Link> */}
                                            //                         <p><Link to={''}>{data.author_name}</Link></p>
                                            //                     </div>
                                            //                     <div className="post-date">
                                            //                         <p><i className="las la-calendar"></i> {data.blog_date}</p>
                                            //                     </div>
                                            //                 </div>
                                            //                 <div className="blog-title">
                                            //                     <h6><Link to={process.env.PUBLIC_URL + data.blog_id}>{data.blog_title}</Link></h6>
                                            //                 </div>
                                            //                 <div className="blog-desc">
                                            //                     <div dangerouslySetInnerHTML={{__html: data.blog_description}}></div>
                                            //                 </div>
                                            //             </div>

                                            //         </div>
                                            //     </Col>
                                            // ))
                                        }
                                    </Row>

                                    <div className="text-center">
                                        {/* <Pagination /> */}
                                    </div>
                                </Col>
                                {/* <Col lg="3" md="4" sm="5">
                                    <BlogSidebar />
                                </Col> */}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
          
        )
    
}

export default BlogGrid